<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        addNew: true,
        headers: [
          { text: 'Name', value: 'name' },
          {
            text: 'Enabled',
            value: 'isActive',
            width: '50px',
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
        ],
      },
    };
  },
  methods: {
    addPage({ _id }) {
      this.$add('ab-testcase', { id: _id });
    },
  },
};
</script>
